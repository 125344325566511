import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { UrlObject } from 'url';
import { styled } from '@mui/material/styles';
import { pagesPath } from '@/lib/$path';
import { withAuthn } from '@/utilities/auth/withAuthn';
import { removePathTrailingSlash } from 'next/dist/client/normalize-trailing-slash';
import { denormalizePagePath } from 'next/dist/server/denormalize-page-path';
import { delBasePath, addBasePath } from 'next/dist/shared/lib/router/router';
import {
  isDynamicRoute,
  getRouteRegex,
} from 'next/dist/shared/lib/router/utils';
import { Layout } from '@/components/Layout';
import { Base } from '@/components/atoms/Base';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';

const NotFoundErrorPage: React.VFC = () => {
  const [isError, setIsError] = useState(false);
  const router = useRouter();

  useEffect(() => {
    (async () => {
      const pages = (await Router?.router?.pageLoader.getPageList()) || [];
      const resolvedRoute = resolveDynamicRoute(
        { pathname: Router.asPath },
        pages
      );

      if (
        resolvedRoute.pathname &&
        pages.includes(resolvedRoute.pathname.split('?')[0])
      ) {
        Router.replace(resolvedRoute.pathname, Router.asPath);
      } else {
        setIsError(true);
      }
    })();
  }, []);

  // フォールバック用ロジックがnext.jsからexportされていなかったので、以下の箇所から転載して修正
  // See: https://github.com/vercel/next.js/blob/33255b7f4ed6dd2608d6a6590d137353b6f19f67/packages/next/next-server/lib/router/router.ts#L341-L367
  const resolveDynamicRoute = (
    parsedHref: UrlObject,
    pages: string[],
    applyBasePath = true
  ) => {
    const copyParsedHref = { ...parsedHref };

    if (!copyParsedHref.pathname) {
      return copyParsedHref;
    }

    const cleanPathname = removePathTrailingSlash(
      denormalizePagePath(
        applyBasePath
          ? delBasePath(copyParsedHref.pathname)
          : copyParsedHref.pathname
      )
    );

    if (cleanPathname === '/404' || cleanPathname === '/_error') {
      return copyParsedHref;
    }

    if (!pages.includes(cleanPathname)) {
      pages.some((page) => {
        if (
          isDynamicRoute(page) &&
          getRouteRegex(page).re.test(cleanPathname)
        ) {
          copyParsedHref.pathname = applyBasePath ? addBasePath(page) : page;
          return true;
        }
        return false;
      });
    }
    copyParsedHref.pathname = removePathTrailingSlash(copyParsedHref.pathname);

    return copyParsedHref;
  };

  return (
    <>
      {isError && (
        <Layout>
          <Inner>
            <Title>
              <Text size={'L'} weight={'bold'}>
                エラーが発生しました
              </Text>
            </Title>
            <Card>
              <Message>
                <Text size={'M'} weight={'bold'}>
                  404 - Not Found
                </Text>
              </Message>
              <Message>
                <Text>アクセスしようとしたページが存在しません</Text>
              </Message>
              <ButtonWrapper>
                <Button
                  color="primary"
                  onClick={() => router.push(pagesPath.products.$url())}
                >
                  商品マスタ一覧ページへ戻る
                </Button>
              </ButtonWrapper>
            </Card>
          </Inner>
        </Layout>
      )}
    </>
  );
};

const Inner = styled('div')`
  width: 1120px;
  min-height: 100%;
  margin: 0 auto;
  padding: 24px 0;
`;
const Title = styled('div')`
  margin-bottom: 16px;
`;
const Card = styled(Base)`
  min-height: 100px;
  padding: 16px;
  margin: 0 8px 16px 8px;
  background-color: #ffffff;
`;
const Message = styled('div')`
  margin-bottom: 16px;
`;
const ButtonWrapper = styled('div')`
  text-align: right;
`;

export default withAuthn(NotFoundErrorPage);
